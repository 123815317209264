/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { Box, Card, createStyles, Text, Tabs, Anchor, Table, Badge, Center, Pagination, Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { exportClinicalNote, fetchPatientDetails, getClinicalNotes } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import { formatDate } from '@medplum/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AudioRecorder from './AudioRecorder';
import { IconFileDownload } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import AssessmentDetailsModel from './AssessmentDetailsModel';
import AddCondition from './AddCondition';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {},

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    color: '#1B5390 !important',
    background: 'none !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  tabs: {
    '& .mantine-Tabs-tab': {
      fontSize: '14px',
      color: '#5D6874',
      marginRight: '10px',
      fontWeight: 400,
      borderRadius: '0.25rem !important',
      padding: '0.30rem 0.35rem !important',
    },
    '& button[data-active]': {
      color: '#000',
      background: '#C9E1FF !important',
      backgroundColor: '#C9E1FF !important',
      borderBottom: 'none',
      fontWeight: 400,
      borderRadius: '0.25rem !important',
      padding: '0.30rem 0.35rem !important',
    },
  },

  tabPanel: {
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',
  },

  badge: {
    minWidth: 'auto',
    minHeight: '25px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '0 0.8rem',
  },

  pagination: {
    '& button': {
      border: 'none',
    },
    '& button[data-active]': {
      color: '#1b5390  ',
      background: 'none !important',
      backgroundColor: 'none !important',
      borderBottom: '0px !important',
    },
  },
}));

export function MedicalHistory() {
  const { classes } = useStyles();
  const patientID = useParams().id;
  const defaultQuery = `${
    '{ Patient(id: "' +
    patientID +
    '") { resourceType id name {text} telecom {system value}  birthDate maritalStatus {text} patients: AppointmentList(_reference: patient, _sort:"-_lastUpdated") { resourceType id start status appointmentType { coding {display}}  serviceType  { coding {display}}  participant {actor {reference display}} } } }'
  }`;

  const [query, setQuery] = useState(defaultQuery);
  const medplum = useMedplum();
  const [data, setData] = useState([]);
  const [noDataRow, setNoDataRow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const navigate = useNavigate();
  const isClinicalNote = localStorage.getItem('isClinicalNote');
  const detailsTab = [
    { value: 'appointments', name: 'Appointments' },
    { value: 'clinicalNotes', name: 'Clinical Notes' },
    { value: 'procedures', name: 'Procedures' },
    { value: 'conditions', name: 'Conditions' },
    { value: 'allergies', name: 'Allergies' },
    { value: 'medications', name: 'Medications' },
    { value: 'assessment', name: 'Assessments' },
  ];

  const [activeTab, setActiveTab] = useState(isClinicalNote === 'clinicalNotes' ? isClinicalNote : detailsTab[0].value);
  const [isLoading, setIsLoading] = useState(true);
  const [isAudioRecorderModalOpen, setIsAudioRecorderModalOpen] = useState(false);
  const [selectedAppointmentId] = useState<string | null>(null);
  const [selectedAppointmentData] = useState<any>();
  const [isAssessmentDetailsModalOpen, setIsAssessmentDetailsModalOpen] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [isopenCondition, setIsopenCondition] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  // Unused code leaving for future reference
  // const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null);
  // const [selectedAppointmentData, setSelectedAppointmentData] = useState<any>();
  const appointmentFields = [
    { fieldName: 'appointmentName', displayName: 'Appointment Type' },
    { fieldName: 'appointmentType', displayName: 'Visit Type' },
    { fieldName: 'appointmentTime', displayName: 'Date & Time' },
    { fieldName: 'organization', displayName: 'Hospital' },
    { fieldName: 'practitioner', displayName: 'Practitioner' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const clinicalNotesFields = [
    { fieldName: 'type', displayName: 'Type' },
    { fieldName: 'category', displayName: 'Category' },
    { fieldName: 'date', displayName: 'Date & Time' },
    { fieldName: 'docStatus', displayName: 'Status' },
    { fieldName: 'practitioner', displayName: 'Practitioner' },
    { fieldName: 'actions', displayName: 'Action' },
  ];

  const procedureFields = [
    { fieldName: 'procedureName', displayName: 'Procedure Name' },
    { fieldName: 'visit', displayName: 'Visit Type' },
    { fieldName: 'startDate', displayName: 'Start Date' },
    { fieldName: 'endDate', displayName: 'End Date' },
    { fieldName: 'dignosis', displayName: 'Diagnosis' },
    { fieldName: 'status', displayName: 'Clinical Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const conditionFields = [
    { fieldName: 'conditionName', displayName: 'Condition Name' },
    { fieldName: 'recordedDate', displayName: 'Recorded Date' },
    { fieldName: 'startDate', displayName: 'Onset Start Date' },
    { fieldName: 'endDate', displayName: 'Onset End Date' },
    { fieldName: 'severity', displayName: 'Severity' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const allergiesFields = [
    { fieldName: 'allergyName', displayName: 'Allergy Name' },
    { fieldName: 'category', displayName: 'Type' },
    { fieldName: 'onsetDate', displayName: 'Onset Date' },
    { fieldName: 'reaction', displayName: 'Reaction' },
    { fieldName: 'status', displayName: 'Clinical Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const medicationFields = [
    { fieldName: 'medicationName', displayName: 'Medications Name' },
    { fieldName: 'category', displayName: 'Type' },
    { fieldName: 'startDate', displayName: 'Start Date' },
    { fieldName: 'reapeats', displayName: 'No. of Repeats' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const assessmentFields = [
    { fieldName: 'assessmentName', displayName: 'Assessment Name' },
    { fieldName: 'completionDate', displayName: 'Completion Date' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const successStatus = ['active', 'completed', 'fulfilled', 'final'];
  const failedStatus = ['inactive', 'incomplete', 'cancelled'];
  const warningStatus = ['booked'];
  const infoStatus = ['amended'];
  const blueColorStatus = ['arrived'];

  const statusButton = (status: any) => {
    const formattedStatus = status?.toLowerCase();
    return successStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#DEF5EA' }}>
        {status}
      </Badge>
    ) : failedStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#FAEBEB' }}>
        {status}
      </Badge>
    ) : warningStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#FCF2DF' }}>
        {status}
      </Badge>
    ) : infoStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#F2F2F2' }}>
        {status}
      </Badge>
    ) : blueColorStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#DBF0FD' }}>
        {status}
      </Badge>
    ) : (
      '-'
    );
  };

  // Unused code leaving for future reference
  // const handleOpenAudioRecorderModal = (data: any) => {
  //   setSelectedAppointmentId(data.id);
  //   setIsAudioRecorderModalOpen(true);
  //   setSelectedAppointmentData(data);
  // };


  useEffect(() => {
    if (isClinicalNote === 'clinicalNotes') {
      clinicalNoteData();
    }
    fetchPatientDetails(medplum, query)
      .then(async (response) => {
        setIsLoading(false);
        if (response?.data.Patient.patients?.length) {
          setNoDataRow(false);
          const fetchedAppointments = response?.data.Patient.patients;
          let tempList: any = [];
          let email: any;
          if (response?.data?.Patient?.telecom?.length) {
            response?.data.Patient?.telecom?.map((e: any) => {
              if (e.system === 'email') {
                email = e.value;
              }
            });
          }

          if (activeTab === 'appointments') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              appointmentName: item?.appointmentType?.coding?.[0]?.display ?? '-',display : '-',
              appointmentTime: dateFormate(item?.start),
              status: item?.status,
              appointmentType: item?.serviceType !== null ? item?.serviceType[0]?.coding[0]?.display : '-',
              practitioner: item?.participant.length >= 1 ? item?.participant[1]?.actor?.display : '-',
              organization: item?.participant.length >= 2 ? item?.participant[2]?.actor?.display : '-',
              startDate: item?.start,
              name: response?.data.Patient.name[0].text,
              email: email,
              patientID: response?.data.Patient.id,
            }));
          } else if (activeTab === 'procedures') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              procedureName: item?.code?.coding ? item?.code?.coding[0].display : '-',
              recordedDate: item?.recordedDate || '-',
              startDate: formatDate(item?.performedPeriod?.start) || '-',
              endDate: formatDate(item?.performedPeriod?.end) || '-',
              visit: item?.location?.display || '-',
              status: item?.status,
              dignosis: item?.reasonCode ? item?.reasonCode[0].text : '-',
            }));
          } else if (activeTab === 'conditions') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              conditionName: item?.category?.[0]?.coding?.[0]?.display || '-',
              severity: item?.severity?.coding?.[0]?.display || '-',
              recordedDate: formatDate(item?.recordedDate) || '-',
              startDate: formatDate(item?.onsetPeriod?.start) || '-',
              endDate: formatDate(item?.onsetPeriod?.end) || '-',
              status: item?.status,
              condition: item?.category?.[0]?.coding?.[0]?.code,
              stage: item?.stage?.[0]?.summary?.coding?.[0]?.code,
              severityCode: item?.severity?.coding?.[0]?.code,
              isPrimaryCondition: item?.identifier?.find((data: { value: string; }) => data.value === 'primary-condition' ) ? true : false,
              conditionLabel: item?.category?.[0]?.coding?.[0]?.display,
              stageLable: item?.stage?.[0]?.summary?.coding?.[0]?.display,
              recordedDateTime: item?.recordedDate,
            }));
          } else if (activeTab === 'allergies') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              allergyName: item?.code?.text || '-',
              category: item?.category ? item?.category[0] : '-',
              severity: item?.severity || '-',
              reaction: item?.reaction ? item?.reaction[0].description : '-',
              onsetDate: formatDate(item?.onsetPeriod?.start) || '-',
              visit: item?.location?.display || '-',
              status: item?.clinicalStatus?.coding[0]?.display,
            }));
            tempList = tempList?.filter((item: any) => item.allergyName !== 'Not on File');
          } else if (activeTab === 'medications') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              medicationName: item?.medicationReference
                ? item?.medicationReference?.display
                : item?.medicationCodeableConcept
                  ? item?.medicationCodeableConcept?.coding[0]?.display
                  : '-',
              category: item?.category ? item?.category[0]?.coding[0]?.display : '-',
              reapeats: item?.dispenseRequest?.numberOfRepeatsAllowed || '0',
              startDate: formatDate(item?.dispenseRequest?.validityPeriod?.start) || '-',
              status: item?.status,
            }));

            tempList = tempList?.filter((item: any) => item.status !== undefined);
          } else if (activeTab === 'clinicalNotes') {
            tempList = fetchedAppointments?.map((item: any) => ({
              id: item?.id,
              patient:
                response?.data?.Patient?.name[0]?.family +
                ' ' +
                response?.data?.Patient?.name?.[0]?.given?.[0] +
                ' ' +
                response?.data?.Patient?.name?.[0]?.given?.[1],
              date: formatDate(item?.meta?.lastUpdated) || '-',
              docStatus: item?.docStatus,
            }));
          } else if (activeTab === 'assessment') {
            tempList = await Promise.all(
              fetchedAppointments?.map(async (item: any) => {
                const questionnaireNameQuery = `${
                  '{ Questionnaire(id: "' + item.questionnaire.split('/')[1] + '") { resourceType id name } }'
                }`;
                try {
                  const res = await fetchPatientDetails(medplum, questionnaireNameQuery);
                  return {
                    id: item?.id,
                    assessmentName: res?.data?.Questionnaire?.name,
                    completionDate: formatDate(item.authored),
                    status: item.status,
                  };
                } catch (error) {
                  console.error('Error fetching data:', error);
                  return null;
                }
              })
            );
          }
          if (tempList.length) {
            setData(tempList);
          } else {
            setNoDataRow(true);
            setData([]);
          }
        } else {
          setNoDataRow(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    localStorage.removeItem('isClinicalNote');
  }, [query, activeTab]);

  const displayData = data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const setDetailsLink = (id: string) => {
    let link = '#';
    if (activeTab === 'appointments') {
      link = `${'/Appointment/' + id + '/details'}`;
    } else if (activeTab === 'procedures') {
      link = `${'/Procedure/' + id + '/details'}`;
    } else if (activeTab === 'conditions') {
      link = `${'/Condition/' + id + '/details'}`;
    } else if (activeTab === 'allergies') {
      link = `${'/AllergyIntolerance/' + id + '/details'}`;
    } else if (activeTab === 'medications') {
      link = `${'/MedicationRequest/' + id + '/details'}`;
    } else if (activeTab === 'assessment') {
      link = `${'/Questionnaire/' + id}`;
    }
    return link;
  };

  const downloadPdf = (id: any, patientName: any) => {
    exportClinicalNote(medplum, id)
      .then((response: any) => {
        if (response && response.data) {
          // Determine file type and extension based on Content-Type header
          const contentType = response.headers['content-type'] || '';
          let fileType = 'text/html';
          let fileExtension = 'html';

          if (contentType.includes('pdf')) {
            fileType = 'application/pdf';
            fileExtension = 'pdf';
          } else if (contentType.includes('xml')) {
            fileType = 'text/xml';
            fileExtension = 'xml';
          } else if (contentType.includes('plain')) {
            fileType = 'text/plain';
            fileExtension = 'txt';
          }

          // Create Blob from response data
          const blob = new Blob([response.data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);

          showNotification({ color: 'green', message: `Exported successfully as ${fileExtension.toUpperCase()}` });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      });
  };
  const handleClose = () => {
    setIsAudioRecorderModalOpen(false);
  };

  const dateFormate = (inputDate: string): string => {
    const date = new Date(inputDate);

    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(',', ','); // Remove the comma after the date
  };

  const anchorFieldNames = ['appointmentName', 'procedureName', 'conditionName', 'allergyName', 'assessmentName'];

  const generateTabContent = (tab: any, data: any[], fields: any[]) => (
    <Tabs.Panel value={tab.value} pt="md" className={classes.tabPanel}>
      <Card withBorder radius="md" className={classes.card}>
        <Card.Section className={classes.header} p="lg">
          <Text className="darkText" fw={700}>
            {tab.name}
          </Text>
        </Card.Section>
        <Card.Section className={classes.section} p="lg">
          <Table highlightOnHover style={{ overflowX: 'hidden' }} className={classes.table} verticalSpacing="sm">
            <thead>
              <tr className={classes.tableRow}>
                {fields.map((field) => (
                  <th className={classes.tableRow} key={field.fieldName}>
                    {field.displayName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow} colSpan={appointmentFields.length}>
                    <Center>{'Loading...'}</Center>
                  </td>
                </tr>
              ) : noDataRow ? (
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow} colSpan={appointmentFields.length}>
                    <Center>{'No Data Found'}</Center>
                  </td>
                </tr>
              ) : (
                data.map((item: any) => (
                  <tr className={classes.tableRow} key={item.id}>
                    {fields.map((field) => (
                      <td className={classes.tableRow} key={field.fieldName} style={{ width: field.fieldName === 'conditionType' ? '17%' : ''}}>
                        {anchorFieldNames.includes(field.fieldName) ? (
                            <Anchor
                              component="button"
                              fz="sm"
                              align="left"
                              className={classes.highlitedRow}
                              onClick={function () {
                                if (activeTab === 'assessment') {
                                  navigate(`/QuestionnaireResponse/${item.id}`);
                                } else {
                                  window.open(setDetailsLink(item.id), '_blank');
                                }
                              }}
                            >
                              {item[field.fieldName]}
                            </Anchor>
                        ) : field.fieldName === 'actions' &&  activeTab === 'conditions' ? (
                          <>
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                                backgroundColor: '#3673B6',
                                padding: '7px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                height: '1.6rem',
                              }}
                              target="_blank"
                              to={setDetailsLink(item.id)}
                            >
                              View
                            </Link>
                            <Button
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                                backgroundColor: '#3673B6',
                                padding: '8px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                height: '29px',
                                marginLeft: '5px',
                              }}
                              onClick={() => {
                                setIsopenCondition(true)
                                setSelectedData(item)
                              }}
                            >
                              Edit
                            </Button>
                          </>
                        ) : field.fieldName === 'actions' && activeTab === 'assessment' ? (
                            <Button
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                                backgroundColor: '#3673B6',
                                padding: '5px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                height: '1.5rem',
                              }}
                              onClick={() => openAssessmentModel(item)}
                            >
                              View Details
                            </Button>
                        ) : field.fieldName === 'actions' && activeTab === 'clinicalNotes' ? (
                            <IconFileDownload
                              className="file-donwload"
                              onClick={() => downloadPdf(item.id, item.patient)}
                            />
                        ) : field.fieldName === 'actions' ? (
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                                backgroundColor: '#3673B6',
                                padding: '5px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                              }}
                              target="_blank"
                              to={setDetailsLink(item.id)}
                            >
                              View
                            </Link>
                        ) : field.fieldName === 'status' ? (
                          <>{statusButton(item[field.fieldName])}</>
                        ) : (
                          <>{item[field.fieldName] || '-'}</>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Section>
      </Card>
    </Tabs.Panel>
  );

  const handleTabChange = (tab: any) => {
    setData([]);
    setActiveTab(tab);
    setIsLoading(true);
    setCurrentPage(1);
    if (tab === 'appointments') {
      const newQuery = defaultQuery;
      setQuery(newQuery);
    } else if (tab === 'procedures') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id name {text}  birthDate maritalStatus {text} patients: ProcedureList(_reference: patient) { resourceType id performedPeriod {start end}  status location { display } reasonCode { text } code {coding {display}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'conditions') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id patients: ConditionList(_reference: patient, _sort:"-_lastUpdated") { resourceType id onsetPeriod{start end} recordedDate code { text } encounter { display } category {coding {code display}} severity {coding {code display}} code {coding {code display}} identifier {system value} stage {summary {coding {code display}}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'allergies') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: AllergyIntoleranceList(_reference: patient) { resourceType id code{text} category onsetPeriod{start} reaction{description} clinicalStatus{coding{display}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'medications') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: MedicationRequestList(_reference: patient) { resourceType id medicationReference{display} medicationCodeableConcept{coding{display}} category{coding{display}} status dispenseRequest{ validityPeriod{start} numberOfRepeatsAllowed} }} }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'clinicalNotes') {
      clinicalNoteData();
    } else if (tab === 'assessment') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: QuestionnaireResponseList(_reference: patient, _count:20, _offset:0, _sort:"-_lastUpdated") { resourceType id status authored questionnaire}} }'
      }`;
      setQuery(newQuery);
    }
  };


  const clinicalNoteData = () => {
    setIsLoading(true);
    getClinicalNotes(medplum, patientID)
      .then((response: any) => {
        setIsLoading(false);
        if (response.data) {
          let tempList: any = [];
          tempList = response.data?.map((item: any) => ({
            id: item.id,
            type: item.type,
            patient: item.patient.name,
            date: item?.date ? dateFormate(item.date) : '-',
            docStatus: item?.status,
            practitioner: item.practitioner,
            category: item?.category,
          }));
          if (tempList.length) {
            setData(tempList);
            setNoDataRow(false);
          } else {
            setNoDataRow(true);
            setData([]);
          }
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      });
  };

  const openAssessmentModel = (item: any) => {
    setAssessmentDetails(item);
    // console.log(item);
    setIsAssessmentDetailsModalOpen(true);
  };

  return (
    <Box py="sm" display="block">
      <Tabs
        variant="pills"
        defaultValue={activeTab}
        className={classes.tabs}
        onTabChange={(newTab) => handleTabChange(newTab)}
      >
        <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          {detailsTab.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value}>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {generateTabContent(detailsTab[0], displayData, appointmentFields)}
        {generateTabContent(detailsTab[1], displayData, clinicalNotesFields)}
        {generateTabContent(detailsTab[2], displayData, procedureFields)}
        {generateTabContent(detailsTab[3], displayData, conditionFields)}
        {generateTabContent(detailsTab[4], displayData, allergiesFields)}
        {generateTabContent(detailsTab[5], displayData, medicationFields)}
        {generateTabContent(detailsTab[6], displayData, assessmentFields)}
      </Tabs>
      <Center mt="lg" style={{ justifyContent: 'flex-end' }}>
        <Pagination
          value={currentPage}
          total={Math.ceil(data.length / itemsPerPage)}
          onChange={handlePageChange}
          defaultValue={itemsPerPage}
          className={classes.pagination}
        />
      </Center>
      {isAudioRecorderModalOpen && selectedAppointmentId && (
        <AudioRecorder
          isOpen={isAudioRecorderModalOpen}
          handleClose={handleClose}
          appointmentId={selectedAppointmentId}
          appointmentData={selectedAppointmentData}
        />
      )}
      {isAssessmentDetailsModalOpen && (
        <AssessmentDetailsModel
          isOpen={isAssessmentDetailsModalOpen}
          handleClose={() => setIsAssessmentDetailsModalOpen(false)}
          assessmentDetails={assessmentDetails}
        />
      )}
      {isopenCondition && (
        <AddCondition opened={isopenCondition} close={() => setIsopenCondition(false)} selectedData={selectedData}/>
      )}
    </Box>
  );
}

export default MedicalHistory;