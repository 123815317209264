/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextInput, Text, Input, Divider, PasswordInput, Button, Accordion } from '@mantine/core';
import { IconChevronDown, IconMinus, IconPlus } from '@tabler/icons-react';
import OrganizationPersonalInfo from './OrganizationPersonalInfo';
import OrganizationAdminProps from './OrganizationAdmin';
import BrandConfiguration from './BrandConfiguration';
import { organisationFormFieldProps } from '../../utils/constant';
import { useMedplum } from '@medplum/react';
import { getProjects } from '../../utils/util';
import { verifyOrganizationConnection } from '../../fhirApi';
import { showNotification } from '@mantine/notifications';

export interface brandConfigurationFields {
  domainName: string,
  projectName: string,
  title: string,
  backgroundColor: string,
  textColor: string,
  defaultTextColor: string,
  primaryColor: string,
  secondaryColor: string,
  sidebarColor: string,
  buttonColor: string,
  brandFavicon: string,
  brandLogo: string,
  iconColor: string
}

interface organizationProps {
  orgListResponse: organisationFormFieldProps;
  onUpdateFormData: (formData: any) => void;
}
type Field = {
  id: number;
  formData: {
    type: string;
    baseUrl: string;
    apiUrl?: string;
    authorizationUrl?: string;
    tokenUrl: string;
    clientSecret: string;
    clientId: string;
    privateKey?: string;
    redirectURI?: string;
    scope?: string;
    siteId?: string;
    fhirBasePath?: string;
    ehr?: string;
  };
};

export const OrganizationForm: React.FC<organizationProps> = ({ orgListResponse, onUpdateFormData }) => {
  const medplum = useMedplum();
  const [formData, setFormData] = useState({
    ehr: orgListResponse?.ehr,
    ehrFields: orgListResponse?.ehrFields,
  });
  const [brandConfigSetting, setBrandConfigSetting] = useState(false);
  const [ehrIntegration , setEHRIntegration] = useState(false);

  useEffect(() => {
    getProjects(medplum, medplum.getProject()?.id || '').then((res) => {
      res?.project?.secret?.map((data: any) => {
        if (data.name === 'loadBrandConfig') {
          setBrandConfigSetting(data?.valueBoolean);
        } else if (data.name === 'loadEHRIntegration') {
          setEHRIntegration(data?.valueBoolean);
        }
      });
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  
  useEffect(() => {
    onUpdateFormData(formData);
  }, [formData, onUpdateFormData]);

  const handleSelectChange = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTypeChange = (fieldId: number, fieldName: string, event: any) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.id === fieldId) {
          const updatedFormData = { ...field.formData, [fieldName]: event.target.value };
          return { ...field, formData: updatedFormData };
        } else {
          return field;
        }
      });
    });
  };

  const getDefaultFormData = () => ({
    type: '',
    baseUrl: '',
    apiUrl: '',
    authorizationUrl: '',
    tokenUrl: '',
    clientSecret: '',
    clientId: '',
    privateKey: '',
    redirectURI: '',
    scope: '',
    siteId: '',
    fhirBasePath: '',
  });
  const [fields, setFields] = useState<Field[]>(
    formData.ehrFields?.length > 0 ? formData.ehrFields : [{ id: 1, formData: getDefaultFormData() }]
  );

  const handleAddField = () => {
    setFields((prevFields: any) => [
      ...prevFields,
      { id: prevFields.length > 0 ? prevFields[prevFields.length - 1].id + 1 : 1, formData: getDefaultFormData() },
    ]);
  };

  const testOrganizationConnection = async (
    ehr: string,
    clientId: string,
    privateKey: string,
    tokenUrl: string,
    baseUrl: string,
    secret: string,
    siteId: string,
    scope: string,
  ) => {
    try {
      // Verify the organization connection
      const response = await verifyOrganizationConnection(medplum, {
        ehr,
        clientId,
        privateKey,
        tokenUrl,
        baseUrl,
        secret,
        siteId,
        scope
      });

      // Show success or failure notification
      const successMessage = response.message ? 'Test Connection Verified' : 'Test Connection Failed';
      showNotification({ color: response.message ? 'green' : 'red', message: successMessage });

      // Update form data with connection status
      setFormData((prevData: any) => ({
        ...prevData,
        ehrFields: prevData.ehrFields.map((field: any) =>
          field.id === 1
            ? {
              ...field,
              formData: {
                ...field.formData,
                isConnectionVerified: !!response.message,
              },
            }
            : field
        ),
      }));
    } catch (error) {
      // Extract error message
      const errorMessage =
        (error as any)?.response?.data?.error ||
        'An unexpected error occurred while testing the connection';

      // Show error notification
      showNotification({ color: 'red', message: errorMessage });
    }
  };

  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      ehrFields: fields,
    }));
  }, [fields]);

  const handleRemoveField = (id: number) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== id));
  };

  const handleEhrInputChange = (fieldId: number, fieldName: string, event: any) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, formData: { ...field.formData, [fieldName]: event.target.value } } : field
      )
    );
  };

  // assigning org info data in setFormData hook
  const handleChangeOrgInfo = useCallback((data: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      organizationName: data.organizationName,
      street: data.street ?? prevData.street,
      city: data.city ?? prevData.city,
      pincode: data.pincode ?? prevData.pincode,
      state: data.state ?? prevData.state,
      phoneNo: data.phoneNo ?? prevData.phoneNo,
      countryCode: data.countryCode ?? prevData.countryCode,
      accountId: data.accountId ?? prevData.accountId,
      locationId: data.locationId ?? prevData.locationId,
    }));
  }, []);

  // assigning contact data in setFormData hook
  const handleChangeContactInfo = useCallback((data: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      contactNo: data?.contactNo,
      contactCountryCode: data?.contactCountryCode,
    }));
  }, []);


  // assigning contact data in setFormData hook
  const handleChangeBrandInfo = useCallback((data: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      domainName: data?.domainName,
      projectName: data?.projectName,
      title: data?.title,
      backgroundColor: data?.backgroundColor,
      textColor: data?.textColor,
      buttonColor: data?.buttonColor,
      brandFavicon: data?.brandFavicon,
      brandLogo: data?.brandLogo,
      primaryColor: data?.primaryColor,
      secondaryColor: data?.secondaryColor,
      sidebarColor: data?.sidebarColor,
      defaultTextColor: data?.defaultTextColor,
      iconColor: data?.iconColor
    }));
  }, []);
  return (
    <>
      <form>
        {/* Organization info section */}
        <Accordion defaultValue="organization" transitionDuration={500} className="ehr-integrations">
          <Accordion.Item  value="organization" sx={{ border: 'none !important' }}>
            <Accordion.Control style={{ fontSize: '16px', fontWeight: '700', borderBottom: 'none' }}>
              Organization
            </Accordion.Control>
            <Accordion.Panel>
              <OrganizationPersonalInfo orgListResponse={orgListResponse} onUpdateOrgInfo={handleChangeOrgInfo} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Divider my="sm" />
        
        {/* Organization Admin section */}
        <Accordion defaultValue="organization-admin" transitionDuration={500} className="ehr-integrations">
          <Accordion.Item value="organization-admin" sx={{ border: 'none !important' }}>
            <Accordion.Control style={{ fontSize: '16px', fontWeight: '700', borderBottom: 'none' }}>
              Organization Admin
            </Accordion.Control>
            <Accordion.Panel>
              <OrganizationAdminProps orgListResponse={orgListResponse} onUpdateContactInfo={handleChangeContactInfo} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Brand Configuration section */}
        {brandConfigSetting && (
          <>
            <Accordion transitionDuration={500} className="ehr-integrations">
              <Accordion.Item value="brand-configuration" sx={{ border: 'none !important' }}>
                <Accordion.Control style={{ fontSize: '16px', fontWeight: '700', borderBottom: 'none' }}>
                  Brand Configuration
                </Accordion.Control>
                <Accordion.Panel>
                  <BrandConfiguration orgListResponse={orgListResponse} onUpdateBrandDetails={handleChangeBrandInfo}/>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Divider my="sm" />
          </>
        )}

        {/* EHR Integrations section */}
        {ehrIntegration && (
          <Accordion defaultValue="ehr-integrations" transitionDuration={500} className="ehr-integrations">
            <Accordion.Item value="ehr-integrations" sx={{ border: 'none !important' }}>
              <Accordion.Control style={{ fontSize: '16px', fontWeight: '700', borderBottom: 'none' }}>
                EHR Integrations
              </Accordion.Control>
              <Accordion.Panel>
                <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                  <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                    <Text style={{ fontSize: '14px', color: '#272D41' }}>EHR Vendor</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={9}>
                    <Input
                      component="select"
                      rightSection={<IconChevronDown size={14} stroke={1.5} />}
                      name="ehr"
                      value={formData.ehr}
                      onChange={handleSelectChange}
                    >
                      <option>Select</option>
                      <option value="EPIC">EPIC</option>
                    </Input>
                  </Grid.Col>
                </Grid>
                <div>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <Divider my="sm" />
                      {index > 0 && (
                        <Grid sx={{ margin: '10px 0 0', alignItems: 'center', justifyContent: 'end' }}>
                          <button
                            type="button"
                            style={{
                              backgroundColor: '#FFEAEA',
                              border: 'none',
                              borderRadius: '50%',
                              width: '30px',
                              height: '30px',
                              margin: '0 8px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveField(field.id)}
                          >
                            <IconMinus size={20} style={{ marginTop: '5px' }} />
                          </button>
                        </Grid>
                      )}
                      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                          <Text style={{ fontSize: '14px', color: '#272D41' }}>Integration Type</Text>
                        </Grid.Col>
                        <Grid.Col span={12} lg={9}>
                          <Input
                            component="select"
                            rightSection={<IconChevronDown size={14} stroke={1.5} />}
                            name="type"
                            value={field.formData?.type}
                            onChange={(value: any) => handleTypeChange(field.id, 'type', value)}
                          >
                            <option>Select</option>
                            <option value="provider">Provider</option>
                            <option value="patient">Patient</option>
                            <option value="backendSystem">Backend System</option>
                          </Input>
                        </Grid.Col>
                      </Grid>
                      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                          <Text style={{ fontSize: '14px', color: '#272D41' }}>Base URL</Text>
                        </Grid.Col>
                        <Grid.Col span={12} lg={9}>
                          <TextInput
                            value={field.formData?.baseUrl}
                            placeholder="Base URL"
                            name="baseUrl"
                            onChange={(value: any) => handleEhrInputChange(field.id, 'baseUrl', value)}
                          />
                        </Grid.Col>
                      </Grid>
                      {(field.formData?.type === 'provider' || field.formData?.type === 'patient') && (
                        <>
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>API URL</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <TextInput
                                value={field.formData?.apiUrl}
                                placeholder="API URL"
                                name="apiUrl"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'apiUrl', value)}
                              />
                            </Grid.Col>
                          </Grid>
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>Authorization URL</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <TextInput
                                value={field.formData?.authorizationUrl}
                                placeholder="Authorization URL"
                                name="authorizationUrl"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'authorizationUrl', value)}
                              />
                            </Grid.Col>
                          </Grid>
                        </>
                      )}

                      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                          <Text style={{ fontSize: '14px', color: '#272D41' }}>Token URL</Text>
                        </Grid.Col>
                        <Grid.Col span={12} lg={9}>
                          <TextInput
                            value={field.formData?.tokenUrl}
                            placeholder="Token URL"
                            name="tokenUrl"
                            onChange={(value: any) => handleEhrInputChange(field.id, 'tokenUrl', value)}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                          <Text style={{ fontSize: '14px', color: '#272D41' }}>Client ID</Text>
                        </Grid.Col>
                        <Grid.Col span={12} lg={9}>
                          <PasswordInput
                            value={field.formData?.clientId}
                            placeholder="Client ID"
                            name="clientId"
                            onChange={(value: any) => handleEhrInputChange(field.id, 'clientId', value)}
                            autoComplete="new-password"
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                          <Text style={{ fontSize: '14px', color: '#272D41' }}>Client Secret</Text>
                        </Grid.Col>
                        <Grid.Col span={12} lg={9}>
                          <PasswordInput
                            value={field.formData?.clientSecret}
                            placeholder="Client Secret"
                            name="clientSecret"
                            onChange={(value: any) => handleEhrInputChange(field.id, 'clientSecret', value)}
                            autoComplete="new-password"
                          />
                        </Grid.Col>
                      </Grid>
                      {(field.formData?.type === 'backendSystem') && (
                        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                          <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                            <Text style={{ fontSize: '14px', color: '#272D41' }}>Private Key</Text>
                          </Grid.Col>
                          <Grid.Col span={12} lg={9}>
                            <PasswordInput
                              value={field.formData?.privateKey}
                              placeholder="Private Key"
                              name="privateKey"
                              onChange={(value: any) => handleEhrInputChange(field.id, 'privateKey', value)}
                            />
                          </Grid.Col>
                        </Grid>
                      )}
                      {(field.formData?.type === 'provider' || field.formData?.type === 'patient') && (
                        <>
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>Redirect URI</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <PasswordInput
                                value={field.formData?.redirectURI}
                                placeholder="Redirect URI"
                                name="redirectURI"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'redirectURI', value)}
                              />
                            </Grid.Col>
                          </Grid>
                        </>
                      )}
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>Scope</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <TextInput
                                value={field.formData?.scope}
                                placeholder="Scope"
                                name="scope"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'scope', value)}
                              />
                            </Grid.Col>
                          </Grid>
                      {field.formData?.type === 'backendSystem' && (
                        <>
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>Site ID</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <PasswordInput
                                value={field.formData?.siteId}
                                placeholder="Site ID"
                                name="siteId"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'siteId', value)}
                              />
                            </Grid.Col>
                          </Grid>
                          <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
                            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                              <Text style={{ fontSize: '14px', color: '#272D41' }}>Fhir Base Path</Text>
                            </Grid.Col>
                            <Grid.Col span={12} lg={9}>
                              <TextInput
                                value={field.formData?.fhirBasePath}
                                placeholder="Fhir Base Path"
                                name="fhirBasePath"
                                onChange={(value: any) => handleEhrInputChange(field.id, 'fhirBasePath', value)}
                              />
                            </Grid.Col>
                          </Grid>
                          {field.formData?.type === 'backendSystem' && (formData.ehr === 'EPIC' || formData.ehr === 'Cerner' || formData.ehr === 'NextGen' || formData.ehr === 'AthenaHealth') && (
                            <Grid sx={{ margin: '10px 8px 0', alignItems: 'center', justifyContent: 'end' }} >
                              <Button
                                key={index}
                                sx={{
                                  background: '#3673B6',
                                  padding: '8px 20px',
                                  fontWeight: 600,
                                  '&:hover': {
                                    background: '#3673B6',
                                  },
                                }}
                                type="button"
                                onClick={() => testOrganizationConnection(formData?.ehr || '', field?.formData?.clientId || '', field?.formData?.privateKey || '', field?.formData?.tokenUrl || '', field?.formData?.baseUrl || '', field?.formData?.clientSecret || '', field?.formData?.siteId || '', field?.formData?.scope || '' )}
                              >
                                Test Connection
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                  {fields.length < 3 && (
                    <Grid sx={{ margin: '10px 8px 0', alignItems: 'center', justifyContent: 'end' }}>
                      <Button
                        sx={{
                          background: '#3673B6',
                          padding: '8px 20px',
                          fontWeight: 600,
                          '&:hover': {
                            background: '#3673B6',
                          },
                        }}
                        type="button"
                        onClick={handleAddField}
                      >
                        <IconPlus size={20} />
                        Add API Configuration
                      </Button>
                    </Grid>
                  )}
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}
      </form>
    </>
  );
};
