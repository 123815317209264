/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Button, Divider, Grid, Text, Box } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { healthSummary } from '../../fhirApi';

const AIHealthSummary = () => {
  const patientID = useParams().id || '';
  const medplum = useMedplum();
  const [lastGenerated, setLastGenerated] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientHealthSummary, setPatientHealthSummary] = useState<any>({});

  useEffect(() => {
    getPatientHealthSummary();
  }, []);

  const getPatientHealthSummary = async (refreshSummary: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await healthSummary(medplum, patientID, refreshSummary);
      const attachmentData = JSON.parse(response?.content?.[0]?.attachment?.data || '{}');
      setPatientHealthSummary(attachmentData);
      setLastGenerated(response?.date || '');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = (content: any, level = 1) => {
    return Object.keys(content).map((key, index) => {
      const value = content[key];
      const isObject = typeof value === 'object' && value !== null;
      const marginLeft = level * 20;

      return (
        <div key={key} style={{ marginBottom: '20px', marginLeft: `${marginLeft}px` }}>
          <span style={{ fontWeight: 700, fontSize: '14px', color: '#333', }}>
            {level === 1 ? `${index + 1}. ` : '\u2022 '}
            {key.replace(/([A-Z])/g, ' $1').trim()}:
            {isObject ? (
              <div style={{ margin: '15px' }}>
                {renderContent(value, level + 1)}
              </div>
            ) : (
              <span style={{ fontSize: '14px', margin: '10px 5px', fontWeight: 500 }}>{value}</span>
            )}
          </span>
        </div>
      );
    });
  };

  const renderSection = (title: string, content: any, index: number) => (
    <>
      <h5 style={{ fontWeight: 700, fontSize: '14px', color: '#333', marginBottom: '10px' }}>
        {`${index + 1}. ${title}`}
      </h5>
      {renderContent(content)}
    </>
  );

  return (
    <Box px="lg" py="lg">
      {lastGenerated && (
        <Grid mb={10}>
              <Grid.Col lg={1.5}>
              <Text c="#475467" fz={13} pb={5}>
                  Last Generated
              </Text>
              <Text fw={600} c="#344054">
                  {lastGenerated ? new Date(lastGenerated).toLocaleDateString() : ''}
              </Text>
              </Grid.Col>
          <Grid.Col lg={3}>
            <Button sx={{ backgroundColor: '#3673B6' }} size="sm"  onClick={() => getPatientHealthSummary(true)}>
              <IconRefresh size={20} style={{ marginRight: '5px' }} />
              Refresh
            </Button>
          </Grid.Col>
        </Grid>
      )}
      <Divider color="#EAECF0" />
      <Box className='custom-scrollbar'>
        {isLoading ? (
          <>
            <span className="loading" style={{ display: 'flex', alignItems: 'end'}}>
              <Text fw={600}>Loading</Text>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
            </span>
          </>
        ) : patientHealthSummary && typeof patientHealthSummary === 'object' && Object.keys(patientHealthSummary).length > 0 ? (
          <div
            className="ai_health_summary">
            {Object.keys(patientHealthSummary).map((key, index) => (
              <div key={key}>
                {renderSection(key.replace(/([A-Z])/g, ' $1').trim(), patientHealthSummary[key], index)}
              </div>
            ))}
          </div>
        ) : (
          <p style={{ fontWeight: '500', fontSize: '16px'}}>No AI Health Summary Found.</p>
        )}
      </Box>
    </Box>
  );
};

export default AIHealthSummary;
