/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Grid, TextInput, Text, ColorInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { brandConfigurationFields } from './OrganizationForm';
import { organisationFormFieldProps } from '../../utils/constant';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import FileUpload from '../../components/FileUpload';

interface BrandConfigurationProps {
    orgListResponse: organisationFormFieldProps;
    onUpdateBrandDetails: (formData: brandConfigurationFields) => void;
}

const BrandConfiguration: React.FC<BrandConfigurationProps> = ({ orgListResponse , onUpdateBrandDetails}) => {
    const medplum = useMedplum();
    const [formData, setFormData] = useState<brandConfigurationFields>({
        domainName: orgListResponse?.domainName,
        projectName: orgListResponse?.projectName,
        title: orgListResponse?.title,
        backgroundColor: orgListResponse?.backgroundColor,
        textColor: orgListResponse?.textColor,
        defaultTextColor: orgListResponse?.defaultTextColor,
        primaryColor: orgListResponse?.primaryColor,
        secondaryColor: orgListResponse?.secondaryColor,
        sidebarColor: orgListResponse?.sidebarColor,
        buttonColor: orgListResponse?.buttonColor,
        brandFavicon: orgListResponse?.brandFavicon,
        brandLogo: orgListResponse?.brandLogo,
        iconColor: orgListResponse?.iconColor
    });

    const handleInputChange = (name: string, event: any) => {
        const { value } = event.target ? event.target : event;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: event.target ? value : event ,
        }));
    };
    
    const handleFileChange = (files: any, name: string) => {
        const file = files.target.files[0];
        medplum.createBinary(file, file.name, file.type, () => {})
            .then((binary: Binary) => {
            setFormData((prevData: any) => ({
                ...prevData,
                [name]: binary.url,
            }));
        })
        .catch((error: any) => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        onUpdateBrandDetails(formData);
    }, [formData, onUpdateBrandDetails]);
    
  return (
    <>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Domain Name
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <TextInput
                value={formData?.domainName}
                placeholder="Domain Name"
                name="domainName"
                onChange={(e) => handleInputChange('domainName', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Project Name
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <TextInput
                value={formData?.projectName}
                placeholder="Project Name"
                name="projectName"
                onChange={(e) => handleInputChange('projectName', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Title
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <TextInput
                value={formData?.title}
                placeholder="Title"
                name="title"
                onChange={(e) => handleInputChange('title', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Background Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.backgroundColor}
                placeholder="Background Color"
                name="backgroundColor"
                onChange={(e) => handleInputChange('backgroundColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Text Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.textColor}
                placeholder="Text Color"
                name="textColor"
                onChange={(e) => handleInputChange('textColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Default Text Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.defaultTextColor}
                placeholder="Default Text Color"
                name="defaultTextColor"
                onChange={(e) => handleInputChange('defaultTextColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Primary Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.primaryColor}
                placeholder="Primary Color"
                name="primaryColor"
                onChange={(e) => handleInputChange('primaryColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Secondary Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.secondaryColor}
                placeholder="Secondary Color"
                name="secondaryColor"
                onChange={(e) => handleInputChange('secondaryColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Sidebar Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
            <ColorInput
                value={formData?.sidebarColor}
                placeholder="Sidebar Color"
                name="sidebarColor"
                onChange={(e) => handleInputChange('sidebarColor', e)}
            />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Button Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
                <ColorInput
                    value={formData?.buttonColor}
                    placeholder="Button Color"
                    name="buttonColor"
                    onChange={(e) => handleInputChange('buttonColor', e)}
                />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>
                    Icon Color
                </Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
                <ColorInput
                    value={formData?.iconColor}
                    placeholder="Icon Color"
                    name="iconColor"
                    onChange={(e) => handleInputChange('iconColor', e)}
                />
            </Grid.Col>
        </Grid>
        <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
                <Text style={{ fontSize: '14px', color: '#272D41' }}>Favicon Logo</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={8}>
                <FileUpload handleFileChange={(files) => handleFileChange(files, 'brandFavicon')} imageUrl={formData?.brandFavicon}/>
            </Grid.Col>
      </Grid>
      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Brand Logo</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={8}>
            <FileUpload handleFileChange={(files) => handleFileChange(files, 'brandLogo')} imageUrl={formData?.brandLogo}/>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default BrandConfiguration;