import { Box, Center, Grid, Title, createStyles, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },

  primaryTitle: {
    textAlign: 'left',
    color: '#000',
    float: 'left',
    fontSize: '34px',
    lineHeight: '1.1',
    fontWeight: 300,
    marginBottom: '8px',
  },

  secondaryTitle: {
    color: '#000',
    float: 'left',
    fontSize: '28px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },

  subTitle: {
    color: '#94a3b8',
    marginTop: '10px',
    letterSpacing: '-0.025em',
    fontSize: '14px',
    fontWeight: 300,
  },
}));

export function IntroductionPanel() : JSX.Element {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.paddingMobile}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            minHeight: '100vh',
          }}
        >
          <Grid sx={{ pl: 3, pr: 3 }} style={{ justifyContent: 'center' }}>
            <Grid.Col md={9} lg={9} xs={12} mb="lg">
              <img src="../img/my-wellness-id-logo.png" height={60} />
            </Grid.Col>
            <Grid.Col md={8} lg={8} xs={12}>
              <Title variant="h1" fw="300" className={classes.primaryTitle} w="100%">
                Welcome to <span style={{ fontWeight: 600 }}>{'MyWellnessID'}</span>
              </Title>
              <Text variant="h5" className={classes.subTitle} mb="sm" w="100%">
                Monitor Autoimmune and Inflammatory Diseases in Real-Time With AI Insights
              </Text>
              <Box>
                <img src="../img/signin-bg.svg" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
              </Box>
            </Grid.Col>
          </Grid>
        </Center>
      </Box>
    </>
  );
}
